import { defineStore } from 'pinia';
import { InteractionService } from '@/services/InteractionService';

export const useInteractionStore = defineStore('interaction', {
  /*************************************************
   * STATE
   *************************************************/
  state: () => ({
    loadingInteraction: false,
    loadingInteractionScore: false,
    loadingInteractionBehaviors: false,
    selectedBehaviorSelections: [],
    selectedOptions: []
  }),
  /*************************************************
   * GETTERS
   *************************************************/
  getters: {
    selectedBehaviorFilters: state => {
      return state.selectedBehaviorSelections?.map(b => {
        return {
          behaviorIds: b.behavior.ids,
          optionItemIds: b.selectedOptions?.map(o => o.ids).join(',')
        };
      });
    }
  },
  /*************************************************
   * ACTIONS
   *************************************************/
  actions: {
    async getInteractionById(interactionId) {
      let interaction;
      this.loadingInteractions = true;

      try {
        interaction = await InteractionService.getInteractionById(interactionId);
      } finally {
        this.loadingInteractions = false;
      }

      return interaction;
    },
    async getEvaluationByInteractionId(interactionId) {
      let score;
      this.loadingInteractionScore = true;

      try {
        score = await InteractionService.getEvaluationByInteractionId(interactionId);
      } finally {
        this.loadingInteractionScore = false;
      }

      return score;
    },
    async getBehaviors(memberId, programIds) {
      let behaviors = [];

      if (programIds.length) {
        this.loadingInteractionBehaviors = true;
        try {
          const response = await InteractionService.getBehaviors(memberId, programIds);
          behaviors = response?.data?.behaviors;
        } finally {
          this.loadingInteractionBehaviors = false;
        }
      }

      return behaviors;
    },
    async getBehaviorOptionItems(memberId, programIds, behaviorId) {
      let optionItems = [];

      if (programIds.length && behaviorId) {
        this.loadingInteractionBehaviors = true;

        try {
          const response = await InteractionService.getBehaviorOptionItems(memberId, programIds, behaviorId);
          optionItems = response?.data?.optionItems;
        } finally {
          this.loadingInteractionBehaviors = false;
        }
      }

      return optionItems;
    }
  }
});
