import { defineStore } from 'pinia';
import { useAgentStore, useAuthStore, useEvalStore, useCoachingStore } from '.';
import { AssessmentService } from '@/services/AssessmentService';
import { newAssessment, formatAssessmentForSave, mapDraftToActive } from '@/utils/assessment-helpers';

export const useAssessmentStore = defineStore('assessment', {
  /*************************************************
   * STATE
   *************************************************/

  state: () => ({
    savingAssessment: false,
    startingAssessment: false,
    deletingDraft: false,
    activeAssessment: null
  }),

  /*************************************************
   * ACTIONS
   *************************************************/

  actions: {
    async beginAssessment(agentId, draftId) {
      this.startingAssessment = true;
      const coachingStore = useCoachingStore();
      coachingStore.fetchFocusBehaviors(agentId);
      coachingStore.fetchCoachingMethods();
      const agentStore = useAgentStore();
      // Wait for the agent because we need it for drafts or a new assessment.
      const agent = await agentStore.getAgentById(agentId);
      // Wait for this one because we need it to map a draft.
      const compendiumBehaviors = await coachingStore.fetchCompendiumBehaviors();

      if (draftId) {
        const draft = await this.getAssessmentById(draftId);
        this.activeAssessment = mapDraftToActive(draft, agent, compendiumBehaviors);
      } else {
        await this.createNewAssessment(agent);
      }
      this.startingAssessment = false;
    },
    /**
     * Initializes a new assessment for the given agent.
     * @param {Object} agent The agent to begin an assessment for.
     */
    async createNewAssessment(agent) {
      const authStore = useAuthStore();
      const loggedInUser = await authStore.getLoggedInUser();
      const agentStore = useAgentStore();
      if (agent) {
        this.activeAssessment = newAssessment(
          agent,
          agentStore.selectedAgentSourceId,
          agentStore.selectedProgramId,
          loggedInUser.memberName
        );
      }
    },
    async deleteDraft(draftId) {
      this.deletingDraft = true;
      await AssessmentService.deleteDraft(draftId);
      this.deletingDraft = false;
    },
    /**
     * Toggles (adds or removes) the given evaluation from the list of selected evaluations.
     * NOTE: Hard limit of 3 evals per assessment as per Kelli (Sprint Review 10/29/21)
     * @param {Evaluation} evaluation The evaluation to toggle in the array of selected evals.
     */
    toggleEval(evaluation) {
      const evals = this.activeAssessment.evaluations;
      if (evals.map(e => e.evaluationId).includes(evaluation.evaluationId)) {
        this.activeAssessment.evaluations = evals.filter(val => val.evaluationId !== evaluation.evaluationId);
      } else if (evals.length < 3) {
        this.activeAssessment.evaluations = [...evals, evaluation];
      }
    },
    /**
     * Adds an evaluation comment to the active assessment.
     * @param comment The comment to add to the assessment.
     */
    addEvalComment(comment) {
      comment.tempId =
        this.activeAssessment.comments.length === 0
          ? 1
          : Math.max(...this.activeAssessment.comments.map(c => c.tempId)) + 1;
      this.activeAssessment.comments = [...this.activeAssessment.comments, comment];
    },
    /**
     * Edits the given evaluation comment.
     * @param comment The comment to edit.
     */
    editEvalComment(comment) {
      const indexToEdit = this.activeAssessment.comments.findIndex(c => c.tempId === comment.tempId);
      this.activeAssessment.comments[indexToEdit] = comment;
    },
    /**
     * Deletes a given evaluation comment.
     * @param comment The comment to delete.
     */
    deleteEvalComment(comment) {
      const indexToDelete = this.activeAssessment.comments.findIndex(c => c.tempId === comment.tempId);
      this.activeAssessment.comments.splice(indexToDelete, 1);
    },
    /**
     * Updates the selected enterprise target ids for the assessment.
     * @param {Array} ids A new set of ids.
     */
    updateEnterpriseTargetIds(ids) {
      this.activeAssessment.enterpriseTargetIds = ids;
    },
    /**
     * Takes the combined list of selected general skills and idividual focuses and splits them out into their respective lists.
     * @param {Array} focuses Focuses selected by the Individual Coaching Focus control.
     */
    updateIndividualFocuses(focuses) {
      this.updateAgentTargets(focuses.filter(f => f.hasOwnProperty('compendiumBehaviorId')));
      this.updateGeneralSkills(focuses.filter(f => f.hasOwnProperty('generalSkillId')));
    },
    /**
     * Updates the selected agent targets for the assessment.
     * @param {Array} targets The new list of targets.
     */
    updateAgentTargets(targets) {
      this.activeAssessment.agentTargets = targets;
    },
    /**
     * Updates the selected (or created) general skills for the assessment.
     * @param {Array} skills The new list of general skills.
     */
    updateGeneralSkills(skills) {
      this.activeAssessment.generalSkills = skills;
    },
    /**
     * Sets a new set of selected coaching methods.
     * @param {Array} ids A new set of ids to set as the selected coaching methods.
     */
    updateCoachingMethodIds(ids) {
      this.activeAssessment.coachingMethodIds = ids;
    },
    /**
     * Adds the unique outcome ids to the active assessment.
     * @param {number[]} outcomeIds The outcome ids to add to this assessment.
     */
    addOutcomes(outcomeIds) {
      const currentOutcomes = this.activeAssessment.outcomeIds;
      let outcomeSet = new Set([...currentOutcomes, ...outcomeIds]);
      this.activeAssessment.outcomeIds = Array.from(outcomeSet.values());
    },
    /**
     * Removes the outcome with the given id from the active assessment.
     * @param {number} outcomeId The id of the outcome to remove.
     */
    removeOutcome(outcomeId) {
      const index = this.activeAssessment.outcomeIds.indexOf(outcomeId);
      if (index > -1) {
        this.activeAssessment.outcomeIds.splice(index, 1);
      }
    },
    /**
     * Adds a wrap up comment to the assessment.
     * @param comment The wrap up comment.
     */
    updateFinalComment(comment) {
      this.activeAssessment.finalComment = comment;
    },
    /**
     * Sets the next assessment date for the active assessment.
     * @param {Date} date The next assessment date.
     */
    updateNextAssessmentDate(date) {
      this.activeAssessment.nextAssessmentDate = date;
    },
    /**
     * saves the active assessment and submits it to the api.
     * @param {boolean} draft TRUE if this coaching session should be saved as a draft.
     */
    saveAssessment(draft) {
      // Map the assessment to the correct POST body format.
      const body = formatAssessmentForSave({ ...this.activeAssessment }, draft);

      this.savingAssessment = true;
      return AssessmentService.saveAssessment(body)
        .then(response => {
          this.activeAssessment = null;
          useEvalStore().$reset();
          response.data;
        })
        .catch(error => {
          throw error?.response?.data || error?.message;
        })
        .finally(() => {
          this.savingAssessment = false;
        });
    },
    /**
     * Cancels the active assessment.
     */
    cancelAssessment() {
      this.activeAssessment = null;
      useEvalStore().$reset();
    },
    /**
     * Fetches and returns a coaching session with the given ID.
     * @param {number} coachingSessionId The ID of the coaching session to fetch.
     * @returns
     */
    getAssessmentById(coachingSessionId) {
      return AssessmentService.getAssessmentById(coachingSessionId)
        .then(response => {
          return response.data;
        })
        .catch(error => {
          throw error;
        });
    }
  }
});
