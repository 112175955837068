import { LAUNCHPAD_URL } from '@/application.config';
import { LAUNCHPAD_IFRAME_ID } from '@/resources';
import { crossDomainRequest } from '@/utils/cross-domain-helpers';

export class ConfigService {
  /**
   * Get configuration data associated with the application
   * @returns {Promise} Configuration data for client application.
   */
  static getConfigData(applicationName) {
    return crossDomainRequest(LAUNCHPAD_IFRAME_ID, LAUNCHPAD_URL, 'getConfigData', applicationName);
  }
}
