import { defineStore } from 'pinia';
import { ConfigService } from '../services/ConfigService';

export const useConfigStore = defineStore({
  id: 'config',
  state: () => ({
    config: {},
    applicationName: 'develop'
  }),
  getters: {},
  actions: {
    /**
     * Gets logged in user info from launchpad.
     */
    getConfigData() {
      return ConfigService.getConfigData(this.applicationName).then(config => {
        this.config = config;
        return config;
      });
    }
  }
});
